<template>
	<div>
		<a-modal v-model:visible="visible" width="800px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader :title="isEdit ? '编辑资产' : '录入资产'"></modalHeader>
			</template>
			<a-spin :spinning="loading">
				<a-form ref="formRef" :model="formModal" :label-col="{ span: 8 }">
					<a-row>
						<a-col :span="12">
							<a-form-item name="code" label="资产编号" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input v-model:value="formModal.code" disabled></a-input>
							</a-form-item>
							<a-form-item name="name" label="资产名称" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-input v-model:value="formModal.name"></a-input>
							</a-form-item>
							<a-form-item name="gainTime" label="取得日期" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-date-picker v-model:value="formModal.gainTime" showTime></a-date-picker>
							</a-form-item>
							<a-form-item name="useType" label="设备用途" class="ui-form__item">
								<!-- <a-input v-model:value="formModal.useType"></a-input> -->
								<a-select allow-clear placeholder="请选择设备用途" v-model:value="formModal.useType" style="width: 190px;">
									<a-select-option v-for="(item, index) in $store.state.count.usageList" :key="item.id" :value="index + 1">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item name="useType" label="规格型号" class="ui-form__item">
								<a-input v-model:value="formModal.model"></a-input>
							</a-form-item>
							<a-form-item name="departmentId" label="使用部门" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-select v-model:value="formModal.departmentId" placeholder="请选择使用部门" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>	
							<a-form-item label="使用人" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<!-- <a-input v-model:value="formModal.user" placeholder="请输入使用人"></a-input> -->
								<a-select v-model:value="formModal.userId" placeholder="请选择使用人" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{ item.username }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="保存地点" class="ui-form__item">
								<a-input v-model:value="formModal.place"></a-input>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="资产分类" name="assetsParameterId" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-cascader v-model:value="formModal.assetsParameterId" :options="$store.state.count.assetClassify" placeholder="请选择资产分类" />
								<!-- <a-input v-model:value="formModal.classifyName"></a-input> -->
								<!-- <a-select v-model:value="formModal.assetsParameterId" placeholder="请选择资产分类">
									<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
							</a-form-item>
							<a-form-item label="取得方式" class="ui-form__item">
								<!-- <a-input v-model:value="formModal.gainType"></a-input> -->
								<a-select allow-clear v-model:value="formModal.gainType" placeholder="请选择取得方式" style="width: 190px;">
									<a-select-option v-for="(item, index) in $store.state.count.methodList" :key="item.id" :value="item.code">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="编制情况" class="ui-form__item">
								<a-input v-if="formModal.organization === 1" disabled placeholder="在编"></a-input>
							</a-form-item>
							<a-form-item label="品牌" class="ui-form__item">
								<a-input v-model:value="formModal.brand"></a-input>
							</a-form-item>
							<a-form-item label="价值(元)" class="ui-form__item">
								<a-input-number v-model:value="formModal.amount"></a-input-number>
							</a-form-item>
							<a-form-item label="使用状况" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<!-- <a-input v-model:value="formModal.conditionType" placeholder="请输入使用状况"></a-input> -->
								<a-select v-model:value="formModal.conditionType" placeholder="请选择使用状况">
									<a-select-option v-for="(item, index) in $store.state.count.useStatusList" :key="item.id" :value="item.code">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="资产归属" class="ui-form__item">
								<a-select v-model:value="formModal.nature"  placeholder="请选择资产归属">
									<a-select-option :value="1">单位</a-select-option>
									<a-select-option :value="2">个人</a-select-option>
									<a-select-option :value="3">租借</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :span="24">
							<a-form-item label="设备照片" class="ui-form__item" :label-col="{ span: 4 }">
								<imageUpload v-model:value="list" :limit="5" :maxSize="2"></imageUpload>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import moment from 'moment';
	import { updateAssets, saveAssets } from '@/service/modules/property.js';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import imageUpload from '@/components/upload/imageUpload.vue';
	export default {
		components: { Icon, imageUpload, modalHeader },
		data() {
			return {
				loading: false,
				visible: false,
				isEdit: false,
				formModal: {},
				list: [],
				tempDate: null,
			}
		},
		methods: {
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = this.$deepClone(this.formModal)
					postData.assetsParameterId = postData.assetsParameterId[postData.assetsParameterId.length - 1];
					let temp = this.transDateTime(postData.gainTime)
					let tempTime = new Date(temp)
					postData.gainTime = tempTime.valueOf()
					postData.gainTime = this.moment(postData.gainTime).unix();
					postData.imgUrl = this.list.join(',')
					postData = JSON.parse(JSON.stringify(postData));
					this.loading = true;
					// console.log("ass",postData,this.formModal)
					try {
						let ret;
						if (this.isEdit) {
							ret = await updateAssets(postData);
						} else {
							ret = await saveAssets(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '录入成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open(e) {
				if (e && e.id) {
					this.formModal = {
						id: e.id,
						name: e.name,
						code: e.code,
						amount: e.amount,
						assetsParameterId: e.assetsParameterId,
						gainType: e.gainType === 0 ? null : e.gainType + '',
						organization: e.organization,
						conditionType: e.conditionType + '',
						useType: e.useType === 0 ? null : e.useType,
						// gainTime: e.gainTime,
						unitId: e.unitId,
						departmentId: e.departmentId,
						user: e.user,
						place: e.place,
						userId: e.userId,
						incomeTime: e.incomeTime,
						// imgUrl: e.imgUrl,
						nature: e.nature !== 0 ? e.nature : null,
						brand: e.brand,
						model: e.model,
						classifyName: e.classifyName,
						departmentName: e.departmentName	
					}
					let tempStr = e.imgUrl
					let tempArr = tempStr? tempStr.split(','): []
					this.list.push(...tempArr)
					this.formModal.gainTime = this.moment.unix(e.gainTime)
					this.isEdit = true;
				} else {
					this.isEdit = false;
				}
				this.visible = true;
			},
			close() { 
				this.visible = false;
				this.list = []
			}
		},
		// watch: {
		// 	tempDate(newVal,oldVal) {
		// 		console.log('newval',newVal)
		// 		let temp = this.transDateTime(newVal)
		// 		let tempTime = new Date(temp)
		// 		this.formModal.gainTime = tempTime.valueOf()
		// 	}
		// }
	}
</script>

<style scoped>
	
</style>