<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<div v-if="this.isExpand" style="display: inline-block; margin-right: 16px;">
						<a-button @click="onSearchBtn">
							<template #icon><Icon icon="SearchOutlined"/></template>
							查询</a-button>
						<importModal class="ui-exportBrn" :type="importType">
							<a-button>
								<template #icon><Icon icon="DownloadOutlined"/></template>
								导入</a-button>
						</importModal>
						<exportModal class="ui-exportBrn" v-permission="['pc_assets_assets_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
							<a-button>
								<template #icon><Icon icon="UploadOutlined"/></template>
								导出</a-button>
						</exportModal>
						<exportModal class="ui-exportBrn" style="margin-right: 16px;" v-permission="['pc_assets_assets_export']" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
							<a-button>
								<template #icon><Icon icon="LogoutOutlined"/></template>
								导出全部</a-button>
						</exportModal>
						<a-button @click="onSearch">
							<template #icon><Icon icon="ReloadOutlined"/></template>
							刷新</a-button>
						<a-button @click="onSort">
							<template #icon><Icon icon="OrderedListOutlined"/></template>
							排序</a-button>
					</div>
					<a-button @click="onExpand">
						<template #icon><Icon icon="ReadOutlined"/></template>
						展开/折叠</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<!-- <a-button type="primary" @click="onInputModal">录入资产</a-button> -->
					<!-- <a-button type="primary" style="background: #49D4F2;border-color: #49D4F2;">资产转移</a-button> -->
				</a-col>
			</a-row>
			
			<a-row>
				<div class="ui-count">
					总资产数：<span>{{assetsData.num}}</span>件
				</div>
				<div class="ui-count">
					资产总价值：<span>{{assetsData.price}}</span>元
				</div>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="资产编号" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input v-model:value="formModal.startCode"></a-input>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input v-model:value="formModal.endCode"></a-input>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="价值区间" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input v-model:value="formModal.startAmount"></a-input>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input v-model:value="formModal.endAmount"></a-input>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="资产名称" class="ui-form__item">
						<a-input v-model:value="formModal.name"></a-input>
					</a-form-item>
					<a-form-item label="资产分类" class="ui-form__item">
						<a-cascader v-model:value="formModal.assetsParameterId" :options="$store.state.count.assetClassify" placeholder="请选择资产分类" />
						<!-- <a-select  allow-clear v-model:value="formModal.assetsParameterId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select> -->
					</a-form-item>
					<a-form-item label="使用状况" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.conditionType" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.useStatusList" :key="item.id" :value="item.code">{{ item.name }}</a-select-option>
						</a-select>		
					</a-form-item>
					<a-form-item label="设备用途" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.useType" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.usageList" :key="item.id" :value="item.code">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="单位" class="ui-form__item">
						<a-select allowClear showSearch optionFilterProp="label" v-model:value="formModal.unitId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id" :label="item.name">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="使用部门" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.departmentId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="使用人" class="ui-form__item">
						<!-- <a-input v-model:value="formModal.user"></a-input> -->
						<a-select v-model:value="formModal.userId" allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{ item.username }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="取得方式" class="ui-form__item">
						<a-select v-model:value="formModal.gainType" allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.methodList" :key="item.id" :value="item.code">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="取得时间段" class="ui-form__item">
						<a-range-picker @change="onChangeFun" :value="clearTime" show-time />
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'name'">资产名称</a-checkbox>
							<a-checkbox :value="'a.code'">资产编号</a-checkbox>
							<a-checkbox :value="'amount'">价值</a-checkbox>
							<a-checkbox :value="'gainTime'">取得时间</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;" v-show="!isExpand">
				<a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="briefList" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'count'">
							<Icon icon="PlusSquareOutlined" @click="onExpand"></Icon>
							<span>小计</span>
						</template>
					</template>
				</a-table>
			</div>
			<div style="margin-top: 20px;" v-show="isExpand">
				<cTable ref="cTable" :columns="expandColumns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'count'">
							<Icon icon="PlusSquareOutlined" @click="onExpand"></Icon>
							<span>小计</span>
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.gainTime) }}
						</template>
						<template v-if="column.key === 'gainType'">
							<div v-if="record.gainType === 0"></div>
							<div v-if="record.gainType === 1">新购</div>
							<div v-if="record.gainType === 2">调拨</div>
							<div v-if="record.gainType === 3">接受捐赠</div>
							<div v-if="record.gainType === 4">自建</div>
							<div v-if="record.gainType === 5">置换</div>
							<div v-if="record.gainType === 6">盘盈</div>
							<div v-if="record.gainType === 7">自行研制</div>
							<div v-if="record.gainType === 8">其他</div>
							<div v-if="record.gainType === 9">PPP模式</div>
						</template>
						<template v-if="column.key === 'useType'">
							<div v-if="record.useType === 0"></div>
							<div v-if="record.useType === 1">办公</div>
							<div v-if="record.useType === 2">教学</div>
							<div v-if="record.useType === 3">培训</div>
							<div v-if="record.useType === 4">执法</div>
							<div v-if="record.useType === 5">窗口</div>
							<div v-if="record.useType === 6">专用</div>
							<div v-if="record.useType === 7">其他</div>
							<div v-if="record.useType === 8">医疗</div>
							<div v-if="record.useType === 9">科研</div>
						</template>
						<template v-if="column.key === 'conditionType'">
							<div v-if="record.conditionType === 1">在用</div>
							<div v-if="record.conditionType === 2">出租</div>
							<div v-if="record.conditionType === 3">出借</div>
							<div v-if="record.conditionType === 4">闲置</div>
							<div v-if="record.conditionType === 5">待处置</div>
						</template>	
						<template v-if="column.key === 'code'">
							<a-tooltip :title="record.code">
								<div class="ui-aBtn" @click="onDetail(record)">{{record.code}}</div>
							</a-tooltip>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_assets_assets_print']" type="link" size="small" @click="onPrint(record)">打印</a-button>
							<span></span>
							<a-button v-permission="['pc_assets_assets_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_assets_assets_delete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
						</template>
					</template>
				</cTable>
				<!-- <a-table rowKey="id" class="table"
					:columns="isExpand ? expandColumns : columns" 
					:dataSource="isExpand ? list : briefList" 
					:pagination="isExpand ? expandPagination : pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'count'">
							<Icon icon="PlusSquareOutlined" @click="onExpand"></Icon>
							<span>小计</span>
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.gainTime) }}
						</template>
						<template v-if="column.key === 'gainType'">
							<div v-if="record.gainType === 0">新购</div>
							<div v-if="record.gainType === 1">调拨</div>
							<div v-if="record.gainType === 2">接受捐赠</div>
							<div v-if="record.gainType === 3">自建</div>
							<div v-if="record.gainType === 4">置换</div>
							<div v-if="record.gainType === 5">盘盈</div>
							<div v-if="record.gainType === 6">自行研制</div>
							<div v-if="record.gainType === 7">其他</div>
						</template>
						<template v-if="column.key === 'useType'">
							<div v-if="record.useType === 0">办公</div>
							<div v-if="record.useType === 1">教学</div>
							<div v-if="record.useType === 2">培训</div>
							<div v-if="record.useType === 3">执法</div>
							<div v-if="record.useType === 4">窗口</div>
							<div v-if="record.useType === 5">专用</div>
							<div v-if="record.useType === 6">其他</div>
							<div v-if="record.useType === 7">医疗</div>
							<div v-if="record.useType === 8">科研</div>
						</template>
						<template v-if="column.key === 'conditionType'">
							<div v-if="record.conditionType === 0">在用</div>
							<div v-if="record.conditionType === 1">出租</div>
							<div v-if="record.conditionType === 2">出借</div>
							<div v-if="record.conditionType === 3">闲置</div>
							<div v-if="record.conditionType === 4">待处置</div>
						</template>	
						<template v-if="column.key === 'code'">
							<a-tooltip :title="record.code">
								<div class="ui-aBtn" @click="onExport(record)">{{record.code}}</div>
							</a-tooltip>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_assets_assets_print']" type="link" size="small" @click="onPrint(record)">打印</a-button>
							<span></span>
							<a-button v-permission="['pc_assets_assets_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		
		<saveModal ref="saveModalRef" @refresh="onReset"></saveModal>
		<detailModal ref="detailModalRef" @onEdit="onEdit" @onPrint="onPrint"></detailModal>
	</div>
</template>

<script>
	// import { getMyAssets } from '@/service/modules/jobs.js';
	import { printLabel } from '@/service/modules/print.js';
	import { getListByUnit, getAssetsList, deleteAssets, getAssetsCount } from '@/service/modules/property.js';
	import { Icon } from '@/components/icon/icon.js';
	import detailModal from './components/detailModal.vue';
	import saveModal from './components/saveModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	import importModal from '@/components/importModal/importModal.vue';
	export default {
		components: { Icon, saveModal, detailModal, cTable, exportModal, importModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {},
				assetsData: {},
				briefList: [],
				clearTime: [], // 存储时间 用来清空重置
				list: [],
				unitId: '', // 财政管理必传参数
				getDataFun: getAssetsList,
				type: 'assetsStrategy', // 导出类型
				importType: 'assetsImportStrategy', // 导入类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '单位',
					align: 'center',
					dataIndex: "title"
				}, {
					title: '资产编号',
					key: 'count',
					align: 'center',
					dataIndex: "code"
				}, {
					title: '总资产数',
					align: 'center',
					dataIndex: "num"
				}, {
					title: '资产总价值',
					align: 'center',
					dataIndex: "amount"
				}],
				expandColumns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '单位',
					align: 'center',
					dataIndex: "unitName"
				}, {
					title: '资产分类',
					align: 'center',
					dataIndex: "classifyName"
				}, {
					title: '资产编号',
					align: 'center',
					key: 'code',
					dataIndex: "code"
				}, {
					title: '资产名称',
					align: 'center',
					dataIndex: "name"
				}, {
					title: '价值',
					align: 'center',
					dataIndex: "amount"
				}, {
					title: '使用状况',
					align: 'center',
					key: 'conditionType',
					dataIndex: "conditionType"
				}, {
					title: '设备用途',
					align: 'center',
					key: 'useType',
					dataIndex: "useType"
				}, {
					title: '使用人',
					align: 'center',
					dataIndex: "user"
				}, {
					title: '存放地',
					align: 'center',
					dataIndex: "place"
				}, {
					title: '取得方式',
					align: 'center',
					key: 'gainType',
					dataIndex: "gainType"
				}, {
					title: '取得时间',
					align: 'center',
					key: 'time',
					// dataIndex: "gainTime"
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 150
				}],
				expandPagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.expandPagination.current = page;
						// this.getData();
						this.onSearch()
					},
					onShowSizeChange: (page, pageSize) => {
						this.expandPagination.current = 1;
						this.expandPagination.pageSize = pageSize;
						this.onSearch()
					}
				},
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getBriefData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getBriefData();
					}
				},
			}
		},
		created() {
			this.onSearch()
			// this.getBriefData()
			// this.getData()
			// this.getAssetsCount()
		},	
		methods: {
			async getAssetsCount() {	// 获取资产总数
				try {
					let ret = await getAssetsCount();
					if (ret.code === 200) {
						this.assetsData = ret.data;
					}
				} catch(e) {
					
				}
			},
			async getBriefData() { // 展开前的请求 获取财政列表必传参数
				this.loading = true;
				try {
					let ret = await getListByUnit({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.briefList = ret.data.list;
						this.unitId = ret.data.list.id
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			// async getData() {
			// 	this.loading = true;
			// 	console.log("unitId",this.unitId)
			// 	try {
			// 		let ret = await getAssetsList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			// unitId: this.unitId,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("lit",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.clearTime = []
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				this.searchData.assetsParameterId = this.searchData.assetsParameterId ? this.searchData.assetsParameterId[this.searchData.assetsParameterId.length - 1] : undefined;
				// this.pagination.current = 1;
				this.getAssetsCount()
				this.getBriefData()
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onExport(item) {
				// this.$refs.detailModalRef.open(item);
				// console.log("export",item)
			},
			onDetail(item) {
				this.$refs.detailModalRef.open(item);
			},
			onExpand() {
				this.isExpand = !this.isExpand;
				if(this.isExpand === false) this.isSearch = false
				if(this.list.length === 0) {
					// this.getData()
					this.onSearch()
				} 
			},
			onInputModal() {
				this.$refs.saveModalRef.open();
			},
			onEdit(item) {
				this.$refs.saveModalRef.open(item);
			},
			onReFresh() {
				// this.getData()
				this.onSearch()
				this.getAssetsCount()
			},
			onPrint(item) {
				this.$confirm({
					title: '提示',
					content: "确定打印当前资产信息吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await printLabel({
								ids: item.id,
							})
							this.loading = false;
							if (ret.code === 200) {
								// this.$download(ret.data);
								if (ret.data) {
									this.$download(ret.data, '资产打印')
									// window.open(ret.data)
								}
								// this.$message.success('打印成功');
								// this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: "确定删除吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteAssets({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								this.onSearch()
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onChangeFun(date, dateString) { // 时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
			// onDelete(item) {
			// 	this.$confirm({
			// 		title: '提示',
			// 		content: "确定删除吗？",
			// 		onOk: async ()=> {
			// 			this.loading = true;
			// 			try {
			// 				let ret = await deleteAssets({
			// 					id: item.id
			// 				})
			// 				this.loading = false;
			// 				if (ret.code === 200) {
			// 					this.$message.success('删除成功');
			// 					this.getData();
			// 				}
			// 			} catch(e) {
			// 				this.loading = false;
			// 			}
			// 		}
			// 	})
			// },
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-count {
		margin-top: 20px;
		margin-right: 60px;
		font-size: 14px;
		font-family: Noto Sans SC-Regular, Noto Sans SC;
		font-weight: 400;
		color: #303A5D;
		line-height: 16px;
	}
	.ui-aBtn {
		color: #1890ff;
		/* width: 250px; */
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.ui-count span {
		padding-right: 4px;
		font-size: 16px;
		font-weight: 700;
		color: #4F7EF5;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>